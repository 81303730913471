<script>
    export let name = 'accept';
    export let outline = true;
    export let size = '100%';

    const dir = '/static/icons/menu/';

    $: path = `${dir}${name}.png`;
</script>

<svelte:head>
    <link rel='preload' as='image' href={path}>
</svelte:head>

<img src={path} alt={name} style={`width: ${size}; height: ${size}`} class='sprite' class:outline>

<style>
    img {
        image-rendering: pixelated;
        margin: auto;
    }
    .outline {
        filter: url('#outline-black-1');
    }
</style>